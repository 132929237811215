import PropTypes from "prop-types";
import { Container } from "@mui/material";
import Page403 from "../pages/Page403";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

const useCurrentRole = () => {
  // Logic here to get current user role
  const role = JSON.parse(window.localStorage.getItem("user"))?.userroles || [];
  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();

  if (
    currentRole.filter(
      (item) => item === accessibleRoles.filter((arole) => arole === item)[0],
    ).length === 0
  ) {
    return (
      <Container>
        <Page403 />
      </Container>
    );
  }

  return <>{children}</>;
}
