// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/app";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  page404: "/404",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/dashboard"),
  },
  products: path(ROOTS_DASHBOARD, "/products"),

  batches: {
    root: path(ROOTS_DASHBOARD, "/batches"),
    available: path(ROOTS_DASHBOARD, "/batches/available"),
  },
  vendors: {
    root: path(ROOTS_DASHBOARD, "/vendors"),
    new: path(ROOTS_DASHBOARD, "/vendors/new"),
  },
  purchases: {
    root: path(ROOTS_DASHBOARD, "/purchases"),
    new: path(ROOTS_DASHBOARD, "/purchases/new"),
  },
  users: {
    root: path(ROOTS_DASHBOARD, "/users"),
    profile: path(ROOTS_DASHBOARD, "/users/profile"),
  },
};
