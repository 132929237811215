import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "layouts/dashboard";
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
// guards
import GuestGuard from "guards/GuestGuard";
import AuthGuard from "guards/AuthGuard";
import RoleBasedGuard from "guards/RoleBasedGuard";

// components
import LoadingScreen from "components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/app")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify/:email", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "app",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/app/dashboard" replace />, index: true },
        { path: "dashboard", element: <Dashboard /> },

        { path: "products", element: <Products /> },
        {
          path: "batches",
          children: [
            {
              element: <Navigate to="/app/batches/all" replace />,
              index: true,
            },
            { path: "all", element: <AllBatches /> },
            { path: "available", element: <AvailableBatches /> },
          ],
        },
        {
          path: "purchases",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard
                  accessibleRoles={["admin", "systemadmin", "stock"]}
                >
                  {" "}
                  <PurchaseList />{" "}
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={["admin", "systemadmin", "stock"]}
                >
                  <PurchaseNew />{" "}
                </RoleBasedGuard>
              ),
            },
            {
              path: "update/:purchaseId",
              element: (
                <RoleBasedGuard
                  accessibleRoles={["admin", "systemadmin", "stock"]}
                >
                  <PurchaseUpdate />{" "}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "vendors",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard
                  accessibleRoles={["admin", "systemadmin", "stock"]}
                >
                  <VendorsList />
                </RoleBasedGuard>
              ),
            },
            { path: "new", element: <VendorsAdd /> },
            {
              path: "update/:vendorId",
              element: (
                <RoleBasedGuard accessibleRoles={["admin", "systemadmin"]}>
                  <VendorsEdit />
                </RoleBasedGuard>
              ),
            },
            {
              path: "details/:vendorId",
              element: (
                <RoleBasedGuard accessibleRoles={["admin", "systemadmin"]}>
                  <VendorsDetails />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: "users",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard accessibleRoles={["systemadmin"]}>
                  <UsersList />
                </RoleBasedGuard>
              ),
            },
            { path: "profile", element: <UserProfile /> },
          ],
        },

        { path: "404", element: <NotFound /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [{ path: "*", element: <Navigate to="/app/404" replace /> }],
    },
    {
      path: "/",
      element: <Navigate to="/app/dashboard" replace />,
      index: true,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("pages/auth/Login")));
const ResetPassword = Loadable(lazy(() => import("pages/auth/ResetPassword")));
const VerifyCode = Loadable(lazy(() => import("pages/auth/VerifyCode")));

// Dashboard
const Dashboard = Loadable(lazy(() => import("pages/Dashboard")));
const NotFound = Loadable(lazy(() => import("pages/Page404")));

// Products
const Products = Loadable(lazy(() => import("pages/products")));

//  atches
const AllBatches = Loadable(lazy(() => import("pages/batches/All")));
const AvailableBatches = Loadable(
  lazy(() => import("pages/batches/Available"))
);

// Vendors
const VendorsList = Loadable(lazy(() => import("pages/vendors/List")));
const VendorsAdd = Loadable(lazy(() => import("pages/vendors/New")));
const VendorsEdit = Loadable(lazy(() => import("pages/vendors/Edit")));
const VendorsDetails = Loadable(lazy(() => import("pages/vendors/Details")));

// Purchases
const PurchaseList = Loadable(lazy(() => import("pages/purchases/List")));
const PurchaseNew = Loadable(lazy(() => import("pages/purchases/New")));
const PurchaseUpdate = Loadable(lazy(() => import("pages/purchases/Update")));

// Users
const UsersList = Loadable(lazy(() => import("pages/users/list")));
const UserProfile = Loadable(lazy(() => import("pages/users/profile.js")));
