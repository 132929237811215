import jwtDecode from "jwt-decode";
//
import axios from "./axios";
import Login from "pages/auth/Login";
// ----------------------------------------------------------------------

const isValidToken = (token) => {
  if (!token) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  expiredTimer = window.setTimeout(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete axios.defaults.headers.common.Authorization;
    return <Login />;
    // You can do what ever you want here, like show a notification
  }, timeLeft);
};

// ----------------------------------------------------------------------

const setSession = (token, user) => {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(token);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
